import { RENTAL_APPLICATION_NOT_FOUND } from '~/assets/strings';
import { RentalApplicationErrorCode, EmploymentInformation, EmploymentHistoryErrorCode } from '~/types/RentalApplication';
import { MagicUseCase } from '~/use-cases/magicUseCase';

export class ValidateRentalApplicationInfoUseCase extends MagicUseCase {
  private nameRegex = /^[a-zA-Z\s]{2,150}$/;
  private ssnRegex = /^\d{3}-{0,1}\d{2}-{0,1}\d{4}$/;
  private dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  private emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  protected async runLogic() {
    const application = this.getState().user.rentalApplication.application;
    if (!application) {
      throw new Error(RENTAL_APPLICATION_NOT_FOUND);
    }
    this.getState().user.rentalApplication.errors = [];
    this.getState().user.rentalApplication.employmentHistoryErrors = [];
    this.validateFirstName();
    this.validateLastName();
    this.validateEmail();
    this.validatePhone();
    this.validateSSN();
    this.validateDateOfBirth();
    this.validateAnnualIncome();
    this.validateEmploymentHistory();
    this.validateHasAccesptedMagicDoorEULA();
    this.validateHasAccesptedTransUnionEULA();
  }

  private validateFirstName = () => {
    const firstName = this.getState().user.rentalApplication.application?.firstName;
    if (!firstName || !this.nameRegex.test(firstName)) {
      this.getState().user.rentalApplication.errors.push(RentalApplicationErrorCode.InvalidFirstName);
    }
  };

  private validateLastName = () => {
    const lastName = this.getState().user.rentalApplication.application?.lastName;
    if (!lastName || !this.nameRegex.test(lastName)) {
      this.getState().user.rentalApplication.errors.push(RentalApplicationErrorCode.InvalidLastName);
    }
  };

  private validateEmail = () => {
    const email = this.getState().user.rentalApplication.application?.email || '';
    const isValid = this.emailRegex.test(email);
    if (!isValid) {
      this.getState().user.rentalApplication.errors.push(RentalApplicationErrorCode.InvalidEmail);
    }
  };

  private validatePhone = () => {
    let isValid = false;
    const phoneNumber = this.getState().user.rentalApplication.application?.phone || '';
    const digitsOnly = phoneNumber.replace(/\D+/g, '');
    if (/^(00|\+)/.test(phoneNumber)) {
      isValid = phoneNumber.length > 3;
    } else {
      isValid = digitsOnly.length === 10;
    }
    if (!isValid) {
      this.getState().user.rentalApplication.errors.push(RentalApplicationErrorCode.InvalidPhone);
    }
  };

  private validateSSN = () => {
    const isValid = this.ssnRegex.test(this.getState().user.rentalApplication.application?.ssn || '');
    if (!isValid) {
      this.getState().user.rentalApplication.errors.push(RentalApplicationErrorCode.InvalidSSN);
    }
  };

  private validateDateOfBirth = () => {
    const dateOfBirth = this.getState().user.rentalApplication.application?.dateOfBirth || '';
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    const isDateOfBirthInvalid = age < 18 || !this.dateRegex.test(dateOfBirth);
    if (isDateOfBirthInvalid) {
      this.getState().user.rentalApplication.errors.push(RentalApplicationErrorCode.InvalidDateOfBirth);
    }
  };

  private validateAnnualIncome = () => {
    const income = this.getState().user.rentalApplication.application?.annualIncome;
    if (!income || income <= 0) {
      this.getState().user.rentalApplication.errors.push(RentalApplicationErrorCode.InvalidAnnualIncome);
    }
  };

  private validateHasAccesptedMagicDoorEULA = () => {
    if (this.getState().user.rentalApplication.application?.hasAcceptedMagicDoorTerms !== true) {
      this.getState().user.rentalApplication.errors.push(RentalApplicationErrorCode.InvalidMagicDoorAgreement);
    }
  };

  private validateHasAccesptedTransUnionEULA = () => {
    if (this.getState().user.rentalApplication.application?.hasAcceptedTransUnionTerms !== true) {
      this.getState().user.rentalApplication.errors.push(RentalApplicationErrorCode.InvalidTransUnionAgreement);
    }
  };

  private validateEmploymentHistory = () => {
    if (this.getState().user.rentalApplication.application?.employmentHistory) {
      this.getState().user.rentalApplication.employmentHistoryErrors = this.getEmploymentHistoryErrorCodes(
        this.getState().user.rentalApplication?.application?.employmentHistory
      );
    }
  };

  private getEmploymentHistoryErrorCodes = (history?: EmploymentInformation[]): EmploymentHistoryErrorCode[][] => {
    if (!history || history.length === 0) {
      return [];
    }
    return history.map((historyElement: EmploymentInformation) => {
      const errors: EmploymentHistoryErrorCode[] = [];
      const fieldsToValidate = [
        { method: this.validateEmployerName, value: historyElement.name },
        { method: this.validateEmployerPhoneNumber, value: historyElement.phone },
        { method: this.validatePosition, value: historyElement.position },
        { method: this.validateStartDate, value: historyElement.startDate },
      ];
      fieldsToValidate.forEach(({ method, value }) => {
        const code = method(value);
        if (code !== undefined) {
          errors.push(code);
        }
      });
      return errors;
    });
  };

  private validateEmployerName = (name?: string): EmploymentHistoryErrorCode | undefined => {
    if (!name || name.length === 0) {
      return EmploymentHistoryErrorCode.InvalidNameError;
    }
  };

  private validateEmployerPhoneNumber = (phoneNumber?: string): EmploymentHistoryErrorCode | undefined => {
    if (phoneNumber && !this.isValidPhoneNumber(phoneNumber)) {
      return EmploymentHistoryErrorCode.InvalidPhoneError;
    }
  };

  private validatePosition = (position?: string): EmploymentHistoryErrorCode | undefined => {
    if (!position || position.length === 0) {
      return EmploymentHistoryErrorCode.InvalidPositionError;
    }
  };

  private validateStartDate = (date?: string): EmploymentHistoryErrorCode | undefined => {
    if (!date || date.length === 0) {
      return EmploymentHistoryErrorCode.InvalidStartDateError;
    }
  };

  private isValidPhoneNumber = (phoneNumber: string): boolean => {
    let isValid = false;
    const digitsOnly = phoneNumber.replace(/\D+/g, '');
    if (/^(00|\+)/.test(phoneNumber)) {
      isValid = phoneNumber.length > 3;
    } else {
      isValid = digitsOnly.length === 10;
    }
    return isValid;
  };
}
